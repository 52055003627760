<template>
  <div class="modal fade " id="assignCouponModal" tabindex="-1" role="dialog" aria-labelledby="assignCouponModal"
       aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Assign Coupon Modal</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="form-group position-relative">
                    <label>Search customer</label>
                    <VueMultiselect v-model="selectedCustomer" placeholder="Type to search" label="name"
                                    track-by="name" id="ajax" open-direction="bottom" :options="customerOptions"
                                    :searchable="true" :loading="isLoading" :options-limit="300" :limit="3"
                                    :show-no-results="true" :hide-selected="true"
                                    @search-change="findCustomer"/>

                  </div>
                </div>
              </div>
            </div>

            <div class="users-list-table">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'30%'}">NAME</th>
                    <th class="position-relative" :style="{width:'70%'}">DETAILS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(couponUser) in couponUsers" :key="couponUser.id">
                    <td class="text-capitalize">{{ `${couponUser.user.first_name} ${couponUser.user.last_name}` }}</td>
                    <td>
                      <span>email: {{ couponUser.user?.email ?? 'Not Provided' }}</span><br>
                      <span>phone: {{ couponUser.user?.phone_number ?? 'Not Provided' }}</span><br>
                      <span>address: {{ userFullAddress(couponUser.user.customer) }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
  name: "AssignCouponModal",
  mixins: [loader, ShowToastMessage],
  props: {
    couponId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      selectedCustomer: '',
      isLoading: false,
      userId: '',
      getCustomersParams: {
        with_relation: ['user', 'address', 'address.business', 'referredBy'],
        where_has_user_search_query: '',
        status: 1,
      },
    }
  },
  computed: {
    ...mapGetters({
      couponUsers: 'appCouponUser/couponUsers',
    }),
    customerOptions() {
      let customers = this.$store.getters['appCustomers/customers'].map((customer) => {
        let id = customer.user.id;
        let name = `${customer.user.first_name} ${customer.user.last_name} < ${customer.user?.email ?? ''} , ${customer.user?.phone_number ?? ''}>`;

        return {
          value: id,
          name: name,
          customer: customer,
        };
      }).filter((user) => !this.couponUsers.find((couponUser) => couponUser.user.id === user.value));
      return [...customers];
    },
  },
  watch: {
    couponId() {
      this.getAllCouponUsers();
    },
    selectedCustomer() {
      this.userId = this.selectedCustomer.value;
      this.singlePostCouponUser(this.couponId, this.userId);
    }
  },
  methods: {
    ...mapActions({
      getCustomers: 'appCustomers/getCustomers',
      getCouponUsers: 'appCouponUser/getCouponUsers',
      postCouponUsersOnList: 'appCouponUser/postCouponUserOnList'
    }),
    async getAllCouponUsers() {
      let paramObj = {
        coupon_id: this.couponId,
        with_relation: ['user', 'user.customer.address.business']
      };

      await this.getCouponUsers(paramObj).then(async (response) => {
        if (response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async singlePostCouponUser(couponId, userId) {
      this.loader(true);
      const postCouponUserData = {
        coupon_id: couponId,
        user_id: userId
      }
      await this.postCouponUsersOnList(postCouponUserData).then(async (response) => {
        if (response.status === 201) {
          this.loader(false);
          const toastObj = {message: 'Customer added successful.', type: 'success'};
          this.showToastMessage(toastObj);
        }
      });
    },

    async findCustomer(query) {
      if (query.length > 2) {
        this.isLoading = true;
        this.getCustomersParams.where_has_user_search_query = query
        setTimeout(async () => {
          await this.getCustomers(this.getCustomersParams)
              .then((response) => {
                this.isLoading = !!(response && response.message && response.status !== 200);
              });

        }, 900);
      }
    },

    userFullAddress(customerObj) {
      let businessName = customerObj.address?.business?.name ?? '';
      let addressStreet = customerObj.address.street;
      let addressSuburb = customerObj.address.suburb;
      let addressState = customerObj.address.state.toUpperCase();
      let addressPostCode = customerObj.address.post_code;
      let addressCountry = customerObj.address.country;
      return customerObj.type === 'Business' ?
          `${businessName} (${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },
  }

}
</script>

<style>

</style>
