<template>
  <div class="modal fade" id="couponEditModal" tabindex="-1" role="dialog" aria-labelledby="couponEditModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Update Coupon: {{ coupon.name }}</h5>
          <button id="closeModal" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Coupon Code</label>
                  <input v-model="updateSingleCouponData.code" type="text" class="form-control"/>
                  <div class="text-danger" v-if="errors.code">{{ errors.code }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Apply On</label>
                  <VueMultiselect v-model="selectedApplyOn" class="" :options="applyOnOptions"
                                  :close-on-select="true"
                                  placeholder="Select Apply On" label="name" track-by="name" :show-labels="false"
                                  :allow-empty="false"/>
                  <div class="text-danger" v-if="errors.applyOn">{{ errors.applyOn }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Discount Type</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input :disabled="selectedApplyOn.value === 2" v-model="selectedDiscountTypeValue" type="checkbox" class="custom-control-input"
                                 checked id="discountTypeStatusCheckbox">
                          <label class="custom-control-label mr-1" for="discountTypeStatusCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ selectedDiscountTypeValue ? "Fixed Amount" : "Percentage" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.discountType">{{ errors.discountType }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <label>{{ selectedApplyOn.value === 2 ? "Grand Total" : "Discount" }}
                    {{ selectedDiscountTypeValue === true ? 'Amount' : 'Percentage' }}</label>
                  <input type="text" v-model="discountValue" class="form-control"
                         :placeholder="selectedDiscountTypeValue === true
                  ? `Enter ${selectedApplyOn.value === 2 ? 'Grand Total' : 'Discount'} Amount`
                  : 'Enter Discount Percentage'"/>
                  <div class="text-danger" v-if="errors.discount">{{ errors.discount }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <label>Activation Amount</label>
                  <input v-model="activationAmountValue" type="text" class="form-control"/>
                  <div class="text-danger" v-if="errors.activationAmount">{{ errors.activationAmount }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <label>End Time <i @click="showEndTimeInfo" class='bx bx-info-circle bx-xs'></i></label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="d-flex align-items-center">
                      <i class='bx bx-calendar'></i>
                    </div>
                    <div class="w-100 pl-1">
                      <div class="w-100 ">
                        <DatePicker v-model="couponEndAt" :update-on-input="true"
                                    mode="dateTime"
                                    :min-date="new Date()"
                                    :masks="{ inputDateTime: ['DD MMMM YYYY hh:mm A'] }"
                                    :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }">
                          <template v-slot="{ inputValue, inputEvents }">
                            <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                          </template>
                        </DatePicker>
                      </div>
                    </div>
                  </div>
                  <span id="show-end-time-info-text">End date-time of the coupon. Keep blank for no end time.</span>
                </div>
                <div class="text-danger" v-if="errors.endAt">{{ errors.endAt }}</div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <div class="d-flex align-items-center">
                      <label>Usage Per Coupon <i @click="showUsagePerCouponInfo"
                                                 class='bx bx-info-circle bx-xs'></i></label>
                    </div>
                    <input v-model="updateSingleCouponData.usage_per_coupon" type="number" step="1"
                           class="form-control"/>
                    <span id="show-edit-coupon-info-text">Limits how many times the coupon can be used by any user. Keep blank for unlimited.</span>
                  </div>
                  <div class="text-danger" v-if="errors.usagePerCoupon">{{ errors.usagePerCoupon }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <div class="d-flex align-items-center">
                      <label>Usage per user <i @click="showUsagePerUserInfo"
                                               class='bx bx-info-circle bx-xs'></i></label>
                    </div>
                    <input v-model="updateSingleCouponData.usage_per_user" type="number" step="1" class="form-control"/>
                    <span id="show-edit-user-info-text">Limits how many times a specific user can be use the coupon. Keep blank for unlimited.</span>
                  </div>
                  <div class="text-danger" v-if="errors.usagePerUser">{{ errors.usagePerUser }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <div class="form-group">
                  <div class="controls">
                    <label>Coupon Type</label>
                    <div class="" style="margin-top: 0.5rem">
                      <div
                          class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="selectedCouponTypeValue" type="checkbox" class="custom-control-input"
                                 id="typeStatusCheckbox"/>
                          <label class="custom-control-label mr-1" for="typeStatusCheckbox"></label>
                        </div>
                        <span class="font-medium-1">
                          {{ selectedCouponTypeValue ? "Global" : "Assigned Only" }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.type">{{ errors.type }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <div class="form-group">
                  <div class="controls">
                    <label>Status</label>
                    <div class="" style="margin-top: .5rem">
                      <div
                          class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="selectedStatusValue" type="checkbox" class="custom-control-input"
                                 id="couponStatusCheckbox">
                          <label class="custom-control-label mr-1" for="couponStatusCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ selectedStatusValue ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.status">{{ errors.status }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <div class="form-group">
                  <div class="controls">
                    <label>Internal Coupon</label>
                    <div class="" style="margin-top: .5rem">
                      <div
                          class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="selectedInternalCouponValue" type="checkbox" class="custom-control-input"
                                 id="selectedInternalCouponValueCheckbox">
                          <label class="custom-control-label mr-1" for="selectedInternalCouponValueCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ selectedInternalCouponValue ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.internalCoupon">{{ errors.internalCoupon }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <div class="form-group">
                  <div class="controls">
                    <label>Website Visibility</label>
                    <div class="" style="margin-top: .5rem">
                      <div
                          class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="selectedWebsiteVisibilityValue" type="checkbox" class="custom-control-input"
                                 id="selectedWebsiteVisibilityValueCheckbox">
                          <label class="custom-control-label mr-1" for="selectedWebsiteVisibilityValueCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ selectedWebsiteVisibilityValue ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.websiteVisibility">{{ errors.websiteVisibility }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <div class="form-group">
                  <div class="controls">
                    <label>Same Day Restriction</label>
                    <div class="" style="margin-top: .5rem">
                      <div
                          class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="selectedSameDayRestrictionValue" type="checkbox" class="custom-control-input"
                                 id="selectedSameDayRestrictionValueCheckbox">
                          <label class="custom-control-label mr-1" for="selectedSameDayRestrictionValueCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ selectedSameDayRestrictionValue ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.sameDayRestriction">{{ errors.sameDayRestriction }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <div class="form-group">
                  <div class="controls">
                    <label>Weekend Restriction</label>
                    <div class="" style="margin-top: .5rem">
                      <div
                          class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="selectedWeekendRestrictionValue" type="checkbox" class="custom-control-input"
                                 id="selectedWeekendRestrictionValueCheckbox">
                          <label class="custom-control-label mr-1" for="selectedWeekendRestrictionValueCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ selectedWeekendRestrictionValue ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.weekendRestriction">{{ errors.weekendRestriction }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-4">
                <div class="form-group">
                  <div class="controls">
                    <label>Holiday Restriction</label>
                    <div class="" style="margin-top: .5rem">
                      <div
                          class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="selectedHolidayRestrictionValue" type="checkbox" class="custom-control-input"
                                 id="selectedHolidayRestrictionValueCheckbox">
                          <label class="custom-control-label mr-1" for="selectedHolidayRestrictionValueCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ selectedHolidayRestrictionValue ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.holidayRestriction">{{ errors.holidayRestriction }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8">
                <div class="form-group">
                  <div class="controls">
                    <div class="d-flex align-items-center">
                      <label>Restricted Post Codes</label>
                    </div>
                    <input v-model="updateSingleCouponData.restricted_post_code" type="text" class="form-control"
                           placeholder="Enter Comma Separated Post Code">
                  </div>
                  <div class="text-danger" v-if="errors.restrictedPostCode">{{ errors.restrictedPostCode }}</div>

                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12">
                <div class="form-group">
                  <div>
                    <label>Usage Condition</label>
                    <textarea v-model="updateSingleCouponData.usage_condition" id="usageCondition"
                              class="form-control"></textarea>
                  </div>
                  <div class="text-danger" v-if="errors.usageCondition">{{ errors.usageCondition }}</div>
                </div>
              </div>

            </div>
          </div>
          <div>
            <div class="">
              <form action="">
                <div class="row">
                  <div class="col-12">
                    <div class="d-flex justify-content-end">
                      <button @click="updateSingleCoupon" type="button" class="btn btn-primary update-todo px-4">
                        Update Coupon
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DatePicker} from 'v-calendar';
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  mixins: [ShowToastMessage, Loader, Authorization],
  name: "CouponEditModal",
  props: ["coupon"],
  components: {
    DatePicker,
  },
  data() {
    return {
      updateSingleCouponData: {
        code: null,
        type: 0,
        discount_type: 0,
        discount: 0,
        activation_amount: 0,
        apply_on: 0,
        end_at: null,
        usage_per_coupon: null,
        usage_per_user: null,
        status: 1,
        internal_coupon: 0,
        website_visibility: 0,
        same_day_restriction: 0,
        weekend_restriction: 0,
        holiday_restriction: 0,
        restricted_post_code: null,
        usage_condition: null,
      },


      errors: {
        code: null,
        type: null,
        discount: null,
        discountType: null,
        activationAmount: null,
        applyOn: null,
        endAt: null,
        status: null,
        internalCoupon: null,
        websiteVisibility: null,
        sameDayRestriction: null,
        weekendRestriction: null,
        holidayRestriction: null,
        restrictedPostCode: null,
        usagePerCoupon: null,
        usagePerUser: null,
        usageCondition: null,
      },

      selectedApplyOn: {
        value: 0,
        name: 'Subtotal'
      },
      applyOnOptions: [
        {value: 0, name: 'Subtotal'},
        {value: 1, name: 'Shipping Fee'},
        {value: 2, name: 'Flat Price'},
      ],

      selectedCouponTypeValue: false,
      selectedDiscountTypeValue: false,
      discountValue: '',
      activationAmountValue: '',
      couponEndAt: '',
      selectedStatusValue: true,
      selectedInternalCouponValue: false,
      selectedWebsiteVisibilityValue: false,
      selectedSameDayRestrictionValue: false,
      selectedWeekendRestrictionValue: false,
      selectedHolidayRestrictionValue: false,


    };
  },
  computed: {
    ...mapGetters({
      updatedCoupon: 'appCoupons/coupon',
    }),
  },
  watch: {
    coupon(currentCoupon) {
      this.updateSingleCouponData.code = currentCoupon.code;

      if (currentCoupon.apply_on === 'Shipping Fee') {
        this.selectedApplyOn.name = 'Shipping Fee';
        this.selectedApplyOn.value = 1;
      } else if (currentCoupon.apply_on === 'Flat Price') {
        this.selectedApplyOn.name = 'Flat Price';
        this.selectedApplyOn.value = 2;
      } else {
        this.selectedApplyOn.name = 'Subtotal';
        this.selectedApplyOn.value = 0;
      }
      this.updateSingleCouponData.apply_on = this.selectedApplyOn.value;

      this.selectedDiscountTypeValue = currentCoupon.discount_type !== 'Percentage';
      this.updateSingleCouponData.discount_type = this.selectedDiscountTypeValue ? 1 : 0;

      this.discountValue = currentCoupon.discount_type !== 'Percentage' ? (currentCoupon.discount / 100).toFixed(2) : currentCoupon.discount
      this.updateSingleCouponData.discount = this.updateSingleCouponData.discount_type === 1 ? this.discountValue * 100 : this.discountValue;

      this.activationAmountValue = (currentCoupon.activation_amount / 100).toFixed(2);
      this.updateSingleCouponData.activation_amount = currentCoupon.activation_amount;

      this.couponEndAt = currentCoupon.end_at;
      this.updateSingleCouponData.end_at = this.couponEndAt ? this.couponEndAt : null;

      this.updateSingleCouponData.usage_per_coupon = currentCoupon.usage_per_coupon;
      this.updateSingleCouponData.usage_per_user = currentCoupon.usage_per_user;

      this.selectedCouponTypeValue = currentCoupon.type !== 'Assigned Only';
      this.updateSingleCouponData.type = this.selectedCouponTypeValue ? 1 : 0;

      this.selectedStatusValue = currentCoupon.status !== 'Inactive';
      this.updateSingleCouponData.status = this.selectedStatusValue ? 1 : 0;

      this.selectedInternalCouponValue = currentCoupon.internal_coupon !== 'Inactive';
      this.updateSingleCouponData.internal_coupon = this.selectedInternalCouponValue ? 1 : 0;

      this.selectedWebsiteVisibilityValue = currentCoupon.website_visibility !== 'Inactive';
      this.updateSingleCouponData.website_visibility = this.selectedWebsiteVisibilityValue ? 1 : 0;

      this.selectedSameDayRestrictionValue = currentCoupon.same_day_restriction !== 'Inactive';
      this.updateSingleCouponData.same_day_restriction = this.selectedSameDayRestrictionValue ? 1 : 0;

      this.selectedWeekendRestrictionValue = currentCoupon.weekend_restriction !== 'Inactive';
      this.updateSingleCouponData.weekend_restriction = this.selectedWeekendRestrictionValue ? 1 : 0;

      this.selectedHolidayRestrictionValue = currentCoupon.holiday_restriction !== 'Inactive';
      this.updateSingleCouponData.holiday_restriction = this.selectedHolidayRestrictionValue ? 1 : 0;

      this.updateSingleCouponData.restricted_post_code = currentCoupon.restricted_post_code;
      this.updateSingleCouponData.usage_condition = currentCoupon.usage_condition;
    },
    couponEndAt(couponEndAt) {
      this.updateSingleCouponData.end_at = couponEndAt;
    },
    activationAmountValue(activationAmountValue) {
      this.updateSingleCouponData.activation_amount = activationAmountValue * 100;
    },
    selectedCouponTypeValue(selectedCouponTypeValue) {
      this.updateSingleCouponData.type = selectedCouponTypeValue ? 1 : 0;
    },
    selectedDiscountTypeValue(selectedDiscountTypeValue) {
      this.updateSingleCouponData.discount_type = selectedDiscountTypeValue ? 1 : 0;
      this.updateSingleCouponData.discount = this.updateSingleCouponData.discount_type === 1 ? this.discountValue * 100 : this.discountValue;
    },
    discountValue(discountValue) {
      this.updateSingleCouponData.discount = this.updateSingleCouponData.discount_type === 1 ? discountValue * 100 : discountValue;
    },
    selectedApplyOn(selectedApplyOn) {
      this.updateSingleCouponData.apply_on = selectedApplyOn.value;

      if (this.updateSingleCouponData.apply_on === 2) {
        this.selectedDiscountTypeValue = true;
        this.updateSingleCouponData.discount = this.discountValue * 100
      }
    },
    selectedStatusValue(selectedStatusValue) {
      this.updateSingleCouponData.status = selectedStatusValue ? 1 : 0;
    },
    selectedInternalCouponValue(selectedInternalCouponValue) {
      this.updateSingleCouponData.internal_coupon = selectedInternalCouponValue ? 1 : 0;
      if (selectedInternalCouponValue) {
        this.selectedWebsiteVisibilityValue = !selectedInternalCouponValue;
        this.updateSingleCouponData.website_visibility = selectedInternalCouponValue ? 0 : 1;
      }
    },
    selectedWebsiteVisibilityValue(selectedWebsiteVisibilityValue) {
      this.updateSingleCouponData.website_visibility = selectedWebsiteVisibilityValue ? 1 : 0;
      if (selectedWebsiteVisibilityValue) {
        this.selectedInternalCouponValue = !selectedWebsiteVisibilityValue;
        this.updateSingleCouponData.internal_coupon = selectedWebsiteVisibilityValue ? 0 : 1;
      }
    },
    selectedSameDayRestrictionValue(selectedSameDayRestrictionValue) {
      this.updateSingleCouponData.same_day_restriction = selectedSameDayRestrictionValue ? 1 : 0;
    },
    selectedWeekendRestrictionValue(selectedWeekendRestrictionValue) {
      this.updateSingleCouponData.weekend_restriction = selectedWeekendRestrictionValue ? 1 : 0;
    },
    selectedHolidayRestrictionValue(selectedHolidayRestrictionValue) {
      this.updateSingleCouponData.holiday_restriction = selectedHolidayRestrictionValue ? 1 : 0;
    }
  },
  methods: {
    ...mapActions({
      putCoupon: 'appCoupons/putCoupon',
      commitUpdateCouponOnList: 'appCoupons/commitUpdateCouponOnList',
    }),

    async updateSingleCoupon() {
      let dataObj = {
        id: this.coupon.id,
        data: this.updateSingleCouponData,
      };
      this.loader(true);
      await this.putCoupon(dataObj).then(async (response) => {
        this.loader(false);
        if (response.status === 200) {
          let responseData = {
            data: this.updatedCoupon,
          }
          await this.resetErrors();
          await this.commitUpdateCouponOnList(responseData);
          document.querySelector('[data-target="#couponEditModal"]').click();

          const toastObj = {message: 'Coupon update successful.', type: 'success'};
          this.showToastMessage(toastObj);
          return;
        }

        this.errors.code = response?.errors?.code?.[0] ?? '';
        this.errors.type = response?.errors?.type?.[0] ?? '';
        this.errors.discount = response?.errors?.discount?.[0] ?? '';
        this.errors.discountType = response?.errors?.discount_type?.[0] ?? '';
        this.errors.activationAmount = response?.errors?.activation_amount?.[0] ?? '';
        this.errors.applyOn = response?.errors?.apply_on?.[0] ?? '';
        this.errors.endAt = response?.errors?.end_at?.[0] ?? '';
        this.errors.status = response?.errors?.status?.[0] ?? '';
        this.errors.internalCoupon = response?.errors?.internal_coupon?.[0] ?? '';
        this.errors.websiteVisibility = response?.errors?.website_visibility?.[0] ?? '';
        this.errors.sameDayRestriction = response?.errors?.same_day_restriction?.[0] ?? '';
        this.errors.weekendRestriction = response?.errors?.weekend_restriction?.[0] ?? '';
        this.errors.holidayRestriction = response?.errors?.holiday_restriction?.[0] ?? '';
        this.errors.usagePerCoupon = response?.errors?.usage_per_coupon?.[0] ?? '';
        this.errors.usagePerUser = response?.errors?.usage_per_user?.[0] ?? '';
        this.errors.usageCondition = response?.errors?.usage_condition?.[0] ?? '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },

    async resetErrors() {
      this.errors = {
        code: null,
        type: null,
        discount: null,
        discountType: null,
        activationAmount: null,
        applyOn: null,
        endAt: null,
        status: null,
        internalCoupon: null,
        websiteVisibility: null,
        sameDayRestriction: null,
        weekendRestriction: null,
        holidayRestriction: null,
        restrictedPostCode: null,
        usagePerCoupon: null,
        usagePerUser: null,
        usageCondition: null,
      };
    },

    showEndTimeInfo() {
      const showEndTimeInfo = document.getElementById("show-end-time-info-text");
      showEndTimeInfo.style.display = showEndTimeInfo.style.display === "none" ? "block" : "none";
    },
    showUsagePerCouponInfo() {
      const showCouponInfo = document.getElementById("show-edit-coupon-info-text");
      showCouponInfo.style.display = showCouponInfo.style.display === "none" ? "block" : "none";
    },
    showUsagePerUserInfo() {
      const showInfo = document.getElementById("show-edit-user-info-text");
      showInfo.style.display = showInfo.style.display === "none" ? "block" : "none";
    },
  },
};
</script>

<style scoped>

#show-edit-coupon-info-text {
  font-size: 12px;
  display: none;
}

#show-edit-user-info-text {
  font-size: 12px;
  display: none;
}

#show-end-time-info-text {
  font-size: 12px;
  display: none;
}

</style>
